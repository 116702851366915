import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
import { Button } from '@toolkit/ui';
// Styles

type Props = {
  isConfirmingBooking:boolean;
};

const CheckoutFooterPayment:React.FC<Props> = (props) => {
  return (
    <Button
      type="submit"
      className="button is--medium"
      isLoading={props.isConfirmingBooking}>
      {t('CheckoutFooter.button.completeBooking')}
      <i className="icon-arrow_forward"/>
    </Button>
  );
};

export default CheckoutFooterPayment;
