import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
import { Button } from '@toolkit/ui';
// Styles

type Props = {
  onPrepareBasket:() => void;
  isPreparingBooking:boolean;
};

const CheckoutFooterReserve:React.FC<Props> = (props) => {
  return (
        <Button
          className="button is--medium"
          isLoading={props.isPreparingBooking}
          onClick={props.onPrepareBasket}>
          {t('CheckoutHeader.button.reserveBooking')}
            <i className="icon-arrow_forward"/>
        </Button>
  );
};

export default CheckoutFooterReserve;
