import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models
// Interfaces
// Components
import { Button } from '@toolkit/ui';
// Styles

type Props = {
  navToPayment:() => void;
  requiresAllowance:boolean;
  exceedsAllowance:boolean;
  bookingId:string;
};

const CheckoutFooterPrepare:React.FC<Props> = (props) => {
  if (props.exceedsAllowance) {
    return null;
  }

  if (props.requiresAllowance) {
    return (
      <Button
        onClick={() =>
          window.location.replace(`${API_URL.DEFAULT}/org_admin/travel/bookings/${props.bookingId}/allowances/new`)}
        className="button is--medium">
        {t('checkoutPrepare.button.requestAllowance')}
        <i className="icon-arrow_forward"/>
      </Button>
    )
  }

  return (
    <Button onClick={props.navToPayment} className="button is--medium">
      {t('CheckoutHeader.button.continueToPayment')}
        <i className="icon-arrow_forward"/>
    </Button>
  );
};

export default CheckoutFooterPrepare;
