import * as React from 'react';

// Utils
import { formatCents } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/CheckoutPrice.scss';
import classNames from 'classnames';

type Props = {
  label: string;
  price: number;
  isTotal?: boolean;
  children?: any;
};

const ItemPrice: React.FC<Props> = (props) => (
  <div className={classNames('lp-checkout-price', { 'is--total': props.isTotal })}>
    <div className='lp-checkout-price-inner'>
      <div className="lp-checkout-price-label">{props.label}</div>
      <div className="lp-checkout-price-number">{formatCents(props.price)}</div>
    </div>
    {props.children}
  </div>
);

export default ItemPrice;
