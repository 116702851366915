import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import {
  cond,
  nthArg,
} from '@src/shared/src/util/general';
import {
  baseErrorCond,
  checkoutNavigationCheck,
  navigate,
  notify,
} from '@toolkit/util/app';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT, STATUS, NOTIF_TYPE } from '@src/shared/src/const/app';
import {
  ROUTES,
  CHECKOUT_STEPS
} from '@toolkit/const/app';
// Actions
import { checkoutActions } from '@src/shared/src/actions';
import {
  adminUserSelectors,
  searchSelectors,
} from '@src/shared/src/selectors';
// Models
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import {
  CheckoutHeader,
  CheckoutBookedItemsDetail,
  CheckoutFooter,
  CheckoutFooterReserve,
} from '@pod/checkout/components';
// Styles

type Props = ConnectedRedux<IRootState> & AppContextProp & {
  env:ENVIRONMENT;
  totalPrice:number;
  isCurrentUserROTraveller:boolean;
  basketId:number;
  searchId:number;
  basketStatus:STATUS;
  isSearchExpired:boolean;
  bookedItemsTotal: number;
};

type State = {
  isPrepareButtonLoading:boolean;
};

class CheckoutReserveConn extends React.PureComponent<Props, State> {
  state:State = {
    isPrepareButtonLoading: false,
  };

  componentDidMount() {
    checkoutNavigationCheck(this.props.basketStatus, CHECKOUT_STEPS.RESERVE, this.props.searchId);
  }

  displayErrors = cond([
    [nthArg(0), () => notify(t('CheckoutReserveConn.alert.notAllowedToBook'), NOTIF_TYPE.ERROR)],
    [nthArg(1), () => notify(t('CheckoutReserveConn.alert.searchExpired'), NOTIF_TYPE.ERROR)],
  ]);

  dispatchPrepareBooking = (basketId:number, searchId:number) => {
    this.setState({ isPrepareButtonLoading: true });
    this.props.dispatch(
      checkoutActions.prepareBookingAsync.request({
        basketId,
        onSuccess: () => {
          navigate(`${ROUTES.BOOKING.PREPARE}${searchId}`);
        },
        onError: (err) => {
          this.setState({ isPrepareButtonLoading: false });
          return cond(baseErrorCond)(err);
        },
      })
    );
  }

  onPrepareBasket = () => {
    const { isCurrentUserROTraveller, isSearchExpired, basketId, searchId } = this.props;

    this.displayErrors(isCurrentUserROTraveller, isSearchExpired);
    if (!isCurrentUserROTraveller && !isSearchExpired) {
      this.dispatchPrepareBooking(basketId, searchId);
    }
  }

  render() {
    const {
      env,
      bookedItemsTotal,
      appContext,
    } = this.props;

    return (
      <React.Fragment>
        <CheckoutHeader>
          {t('CheckoutReserveConn.label.trip')}
        </CheckoutHeader>
        <CheckoutBookedItemsDetail
          env={env}
          showItemsDetails={appContext.isMediaBPNotMobile}
          showPrices={true} />
        <CheckoutFooter
          totalPrice={bookedItemsTotal}>
          <CheckoutFooterReserve
            isPreparingBooking={this.state.isPrepareButtonLoading}
            onPrepareBasket={this.onPrepareBasket}/>
        </CheckoutFooter>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state:IRootState) => ({
  bookedItemsTotal: state.checkout.basket.total,
  isCurrentUserROTraveller: adminUserSelectors.isCurrentUserROTraveller(state.adminUser),
  isSearchExpired: searchSelectors.isSearchExpired(state.search),
});
export default connect(mapStateToProps)(withAppContext(CheckoutReserveConn));
