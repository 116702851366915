import * as React from 'react';

// Utils
import { formatCents, isNil } from '@src/shared/src/util/general';
import { useTranslation } from 'react-i18next';

// Constants
// Actions
// Models
// Interfaces
// Components
// Styles
import '../styles/CheckoutFooter.scss';

type Props = React.PropsWithChildren & {
  totalPrice?: number;
};

const CheckoutFooter: React.FC<Props> = ({ totalPrice, children }) => {
  const { t } = useTranslation();
  return (
    <div className="checkout-footer">
      {!isNil(totalPrice) && (
        <div className="checkout-footer-total">
          {t('CheckoutFooter.label.totalBookable') + ':\u00A0'}
          <div className="checkout-footer-price">{formatCents(totalPrice)}</div>
        </div>
      )}
      {children}
    </div>
  );
};

export default CheckoutFooter;
